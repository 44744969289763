import Vue from 'vue'
import App from './App.vue'
import { initRouter } from './router'
import './theme/index.less'
import Antd, { message } from 'ant-design-vue'
import '@/mock'
import store from './store'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import { initI18n } from '@/utils/i18n'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import './theme/global.less'
import { routerSkip } from '@/utils/routerSkip'
import { changeTime } from '@/utils/time'
// import animated from 'animate.css'
import VMdDateRangePicker from 'v-md-date-range-picker'

// Vue.use(animated)
Vue.use(VMdDateRangePicker)
// import tagCloud from 'v-tag-cloud'
// Vue.use(tagCloud)

// const router = initRouter(store.state.setting.asyncRoutes);
const router = initRouter()
const i18n = initI18n('CN', 'US')

Vue.prototype.routerSkip = routerSkip
Vue.prototype.changeTime = changeTime
Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Plugins)

message.config({
  duration: 3
})

bootstrap({ router, store, i18n, message: Vue.prototype.$message })

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
