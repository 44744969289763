import { request, METHOD } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const { POST: post, GET: get } = METHOD

// 获取主题词相关的文章列表
async function getTopicRelatedArticles(params) {
  return request(`${BASE_URL}/getTopicRelatedArticles`, post, params)
}

// 一次性查询全部
async function searchAll(qs, swname, swuid, qi, type) {
  return request(`${BASE_URL}/searchAll`, post, {
    qs: qs,
    swname: swname,
    swuid: swuid,
    qi: qi,
    mode: type
  })
}

// 底部推荐问题
async function getSearchRelatedQuestions(qs) {
  return request(`${BASE_URL}/getSearchRelatedQuestions`, post, {
    qs: qs
  })
}

// 通过游标加载更多
async function searchSpeechByScroll(type, id, qsterms) {
  return request(`${BASE_URL}/searchSpeechByScroll`, post, {
    type: type,
    scrollid: id,
    qsterms: qsterms
  })
}

async function getRelatedKeywords(uid) {
  return request(`${BASE_URL}/getRelatedKeywords`, post, {
    uid: uid
  })
}

export default {
  searchAll,
  getTopicRelatedArticles,
  getSearchRelatedQuestions,
  searchSpeechByScroll,
  getRelatedKeywords
}
