<template>
  <a-layout-header :class="[headerTheme, 'admin-header']" v-if="false">
    <!-- <div :class="['admin-header-wide', layout, pageWidth]"> -->
    <!-- <router-link
        v-if="isMobile || layout === 'head'"
        to="/homePage"
        :class="['logo', isMobile ? null : 'pc', headerTheme]"
      >
        <!-- <h1 v-if="!isMobile">{{ systemName }}</h1> -->
    <!-- <div class="systemNameBox"></div>
        </router-link> -->
    <!-- -->

    <!-- <div class="typeBox">
        <div class="name">
          <SystemName v-if="!isMobile" size="30" weight="600"></SystemName>
        </div>
        <div class="search">
          <SearchBox :isSearch="false"></SearchBox>
        </div>
      </div>

 
    </div> -->
    <div class="typeBox">
      <div class="systemName">
        <router-link v-if="isMobile || layout === 'head'" to="/homePage" :class="['logo', isMobile ? null : 'pc', headerTheme]">
          <SystemName v-if="!isMobile" size="30" weight="600"></SystemName>
        </router-link>
      </div>
      <div class="systemSearchBox">
        <SearchBox :isSearch="false" v-show="this.$store.state.search.searchBoxShow"></SearchBox>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
  // import HeaderSearch from "./HeaderSearch";
  // import HeaderNotice from "./HeaderNotice";
  // import HeaderAvatar from "./HeaderAvatar";
  // import IMenu from "@/components/menu/menu";
  import { mapState, mapMutations } from 'vuex'
  import SystemName from '@/components/systemName'
  import SearchBox from '@/components/searchBox'

  export default {
    name: 'AdminHeader',
    components: { SystemName, SearchBox },
    props: ['collapsed', 'menuData'],
    data() {
      return {
        searchIsShow: true,
        langList: [
          { key: 'CN', name: '简体中文', alias: '简体' },
          { key: 'HK', name: '繁體中文', alias: '繁體' },
          { key: 'US', name: 'English', alias: 'English' }
        ],
        searchActive: false
      }
    },
    computed: {
      ...mapState('setting', ['theme', 'isMobile', 'layout', 'systemName', 'lang', 'pageWidth']),
      headerTheme() {
        if (this.layout == 'side' && this.theme.mode == 'dark' && !this.isMobile) {
          return 'light'
        }
        return this.theme.mode
      },
      langAlias() {
        let lang = this.langList.find((item) => item.key == this.lang)
        return lang.alias
      },
      menuWidth() {
        const { layout, searchActive } = this
        const headWidth = layout === 'head' ? '100% - 188px' : '100%'
        const extraWidth = searchActive ? '600px' : '400px'
        return `calc(${headWidth} - ${extraWidth})`
      }
    },
    methods: {
      toggleCollapse() {
        this.$emit('toggleCollapse')
      },
      onSelect(obj) {
        this.$emit('menuSelect', obj)
      },
      ...mapMutations('setting', ['setLang'])
    }
  }
</script>

<style lang="less" scoped>
  @import 'index';

  .typeBox {
    max-width: 1400px;
    min-width: 1300px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20% 0px 20%;
  }

  .systemName {
    padding-left: 30px;
    margin-right: 100px;
  }

  .systemSearchBox {
    flex: 1;
  }
</style>
