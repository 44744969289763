<template>
  <!-- <page-toggle-transition
    :disabled="animate.disabled"
    :animate="animate.name"
    :direction="animate.direction"
  > -->
  <div class="auio">
    <router-view />
  </div>
  <!-- </page-toggle-transition> -->
</template>

<script>
  import PageToggleTransition from '../components/transition/PageToggleTransition'
  import { mapState } from 'vuex'

  export default {
    name: 'BlankView',
    components: { PageToggleTransition },
    data() {
      return {}
    },

    methods: {},

    computed: {
      ...mapState('setting', ['multiPage', 'animate'])
    }
  }
</script>

<style scoped>
  .auio {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
