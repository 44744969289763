// 加密解密函数，用base64，防止跳转路由传参是明文
// import base from "./base64.min.js";

// function encode(data) {
//   return encodeURI(data);
// }

// function decodeW(data) {
//   return decodeURI(data);
// }

export function encrypt(data) {
  return encodeURI(data)
}

export function decode(data) {
  return decodeURI(data)
}
// base.encode
// base.decode
// import { encrypt, decode } from '@/util/encrypt';
// let a = encrypt(e);
// console.log('加密前', a);
// let b = decode(a);
// console.log('加密后', b);
