<template>
  <a-tooltip trigger="focus" v-model="visible" placement="bottomRight" :defaultVisible="false">
    <template slot="title">{{ text }}</template>
    <slot></slot>
  </a-tooltip>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        text: '输入内容含有禁用词，不能提交！ '
      }
    },
    methods: {
      open(text) {
        if (text) {
          this.text = text
        } else {
          this.text = '输入内容含有禁用词，不能提交！ '
        }

        this.visible = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.visible = false
          }, 3000)
        })
      }
    }
  }
</script>
