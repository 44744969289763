import _ from 'lodash'
import { request, METHOD } from '@/utils/request'
import { initRouter } from '../router'
const router = initRouter()

// const BASE_URL = process.env.VUE_APP_API_BASE_URL;
// const BASE_URL = "http://112.126.69.131:8088";

const { POST: post, GET: get } = METHOD
const UAS_PREFIX = process.env.VUE_APP_UAS_PREFIX

const alertMessage = (err) => {
  const { response } = err
  const { statusText } = response
  // message.error(statusText);
}

// 获取验证码
async function obtainCode(phoneNo) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/sms/obtainCode`, get, {
      phoneNo: phoneNo
    })
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve(msg)
        } else {
          if (_.isNil(data) || _.isNull(data)) {
            resolve({})
          } else {
            resolve(data)
          }
        }
      })
      .catch((err) => {
        // alertMessage(err);
      })
  })
}

// 校验验证码
async function validateCode(phoneNo, smsCode, requestUID) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/sms/validateCode`, post, {
      phoneNo: phoneNo,
      smsCode: smsCode,
      requestUID: requestUID
    })
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve(msg)
        } else {
          resolve(true)
        }
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 手机号码注册
// phoneNo		手机号码
// pwd		加密密码
// nickName	昵称
// realName	真实姓名
// gender		性别，0-保密；1-男；2-女
// email		电子邮箱地址
// company		单位
// brief		简介
// {
//   phoneNo,
//   pwd,
//   nickName,
//   realName,
//   gender,
//   email,
//   company,
//   brief,
// }
async function registerMobile(obj) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/user/register/mobile`, post, obj)
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve(msg)
        } else {
          resolve(true)
        }
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 验证码登录
async function codeLogin(phoneNo, smsCode, requestUID) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/user/login/mobile`, post, {
      phoneNo: phoneNo,
      smsCode: smsCode,
      requestUID: requestUID
    })
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve(msg)
        } else {
          resolve(data)
        }
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 密码登录
async function pwdLogin(phoneNo, pwd) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/user/login`, post, {
      phoneNo: phoneNo,
      pwd: pwd
    })
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve(msg)
        } else {
          resolve(data)
        }
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 组织登录
function orgLogin(username, pwd) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/user/login/org`, post, {
      username,
      pwd
    })
      .then((res) => {
        const { code, data, msg } = res.data
        if (code !== 0) {
          resolve(msg)
        } else {
          resolve(data)
        }
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 组织申请
function orgApply(params) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/userapply/addOrgAccountUserApply`, post, {
      ...params
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 续费申请
function renewApply(params) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/userapply/addUserMemberRenewApply`, post, {
      ...params
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 认证访问资源的令牌
async function authorize(accessToken) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/oauth/authorize`, post, {
      accessToken: accessToken
    })
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve({})
        } else {
          resolve(data)
        }
      })
      .catch((err) => {
        // alertMessage(err);
      })
  })
}

// 获取用户信息

async function userInfo() {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/user/info`, get)
      .then((res) => {
        const { code, data, msg } = res.data
        if (code != 0) {
          resolve({})
        } else {
          resolve(data)
        }
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

// 登出
// async function logout() {
//   return new Promise((resolve, reject) => {
//     request(`${UAS_PREFIX}/user/logout`, get)
//       .then((res) => {
//         const { code, data, msg } = res.data;
//         if (code != 0) {
//           resolve(true);
//         } else {
//           resolve(true);
//         }
//       })
//       .catch((err) => {
//         alertMessage(err);
//       });
//   });
// }

// 刷新token
async function refreshToken() {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/oauth/refreshToken`, get).then((res) => {
      if (!res || res.code) {
        resolve({ code: res.code })
        return
      }

      const { code, data, msg } = res.data
      if (code != 0) {
        resolve(msg)
      } else {
        resolve(data)
      }
    })
  })
}

export async function loginByThridToken(thirdName, thirdToken) {
  return new Promise((resolve, reject) => {
    request(`${UAS_PREFIX}/user/login/loginByThirdToken`, post, {
      thirdName: thirdName,
      thirdToken: thirdToken
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        alertMessage(err)
      })
  })
}

export default {
  obtainCode,
  validateCode,
  registerMobile,
  codeLogin,
  pwdLogin,
  authorize,
  userInfo,
  // logout,
  refreshToken,
  orgLogin,
  orgApply,
  renewApply,
  loginByThridToken
}
