import { reqdata, METHOD } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const { POST: post, GET: get } = METHOD

export async function getUserContent(params) {
  return reqdata(`${BASE_URL}/partyorg/datacenter/getUserContentIndex`, post, params)
}

export async function getStudyRank(params) {
  return reqdata(`${BASE_URL}/partyorg/datacenter/getStudyRankList`, post, params)
}

export async function getStudyTrend(params) {
  return reqdata(`${BASE_URL}/partyorg/datacenter/getStudyUserNumByTimeDimension`, post, params)
}

export default {
  getUserContent,
  getStudyRank,
  getStudyTrend
}
