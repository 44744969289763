import { encrypt, decode } from '@/utils/encrypt'
import _ from 'lodash'

// 非ie状态下跳转
function toNewPage(router, page, data) {
  let url = page === 4 ? '/results' : '/details'
  let query = page === 4 ? { qs: encrypt(data) } : { docuid: encrypt(data) }
  let routeUrl = router.resolve({ path: url, query })
  window.open(routeUrl.href, '_blank')
}

// 检查是否为ie
function checkIE() {
  return '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style
}

// ie下获取要跳转的页面
function getToSkipPageUrl(page) {
  let url = ''
  switch (page) {
    case 1:
      url = 'homePage/'
      break
    case 2:
      url = 'results'
      break
    case 3:
      url = 'details'
      break
    case 4:
      url = 'results'
      break
    case 5:
      url = 'speech'
      break
  }
  return url
}

// 跳转要携带的参数
function getQueryData(page, value) {
  let data = ''
  const str = encrypt(value)
  switch (page) {
    case 1:
      data = ''
      break
    case 2:
      data = `?qs=${str}`
      break
    case 3:
      data = `?docuid=${str}`
      break
    case 4:
      data = `?qs=${str}`
      break
  }
  return data
}

// ie下跳转
function toSkipInIE(page, url) {
  switch (page) {
    case 1:
      window.location.replace(url)
      break
    case 2:
      window.location.replace(url)
      break
    case 3:
      window.open(url)
      break
    case 4:
      window.open(url)
      break
  }
}

export function routerSkip(router, page, data) {
  // 1=homepage,2=results,3=details
  if (checkIE()) {
    const value = data
    const presentBaseUrl = _.split(window.location.href, '#')[0]
    const presentPageUrl = _.split(window.location.href, '#')[1]
    const skipUrl = `${presentBaseUrl}#/${getToSkipPageUrl(page)}${getQueryData(page, value)}`
    toSkipInIE(page, skipUrl)
  } else {
    const value = data
    switch (page) {
      case 1:
        router.push({
          path: '/homePage'
        })
        break
      case 2:
        router.push({
          path: '/results',
          query: { qs: encrypt(value) }
        })
        break
      case 3:
        toNewPage(router, 3, value)
        break
      case 4:
        toNewPage(router, 4, value)
        break
      case 5:
        toNewPage(router, 5, value)
        break
    }
  }
}

// this.routerSkip(this.$router, 2, query.qs);  //直接提交要传递的值
