import { request, METHOD } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const { POST: post, GET: get } = METHOD

// 获得热门问题列表
async function getHotQuestions() {
  return request(`${BASE_URL}/getHotQuestions`, post)
}

// 获得搜索框输入的及时推荐
async function getQueryAutoQuestion(qs) {
  return request(`${BASE_URL}/getQueryAutoQuestion`, post, {
    qs: qs
  })
}

// 搜索审查接口
async function queryCensor(qs) {
  return request(`${BASE_URL}/queryCensor`, post, {
    qs: qs
  })
}

async function newFeedback(params) {
  return request(`${BASE_URL}/feedback/newFeedback`, post, params)
}

export default {
  getHotQuestions,
  getQueryAutoQuestion,
  queryCensor,
  newFeedback
  // newErrcorrect,
}
