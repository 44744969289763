// 自定义配置，参考 ./default/setting.config.js，需要自定义的属性在这里配置即可
module.exports = {
  theme: {
    color: '#de3737',
    mode: 'light',
    success: '#52c41a',
    warning: '#faad14',
    error: '#f5222f'
  },
  layout: 'head',
  fixedHeader: false,
  fixedSideBar: false,
  pageWidth: 'fluid',
  multiPage: false,
  animate: {
    disabled: true,
    name: 'fade',
    direction: 'right'
  }
}
