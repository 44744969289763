import { getCurrentUserInfo, loginFree } from '@/services/user'
import { loginByThridToken } from '@/services/newLogin'
import { setLocalUser, setLocalLogined } from '@/utils/util'

export default {
  namespaced: true,
  state: {
    user: undefined,
    permissions: null,
    roles: null,
    routesConfig: null,
    isLogin: false,
    freeLogin: true
  },
  getters: {
    freeLogin: (state) => {
      return state.freeLogin
    },
    user: (state) => {
      // if (!state.user) {
      //   try {
      //     const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
      //     state.user = JSON.parse(user)
      //   } catch (e) {
      //     // console.error(e)
      //   }
      // }
      return state.user
    },
    isLogin: (state) => {
      return state.isLogin
    },
    permissions: (state) => {
      if (!state.permissions) {
        try {
          const permissions = localStorage.getItem(process.env.VUE_APP_PERMISSIONS_KEY)
          state.permissions = JSON.parse(permissions)
          state.permissions = state.permissions ? state.permissions : []
        } catch (e) {
          // console.error(e.message)
        }
      }
      return state.permissions
    },
    roles: (state) => {
      if (!state.roles) {
        try {
          const roles = localStorage.getItem(process.env.VUE_APP_ROLES_KEY)
          state.roles = JSON.parse(roles)
          state.roles = state.roles ? state.roles : []
        } catch (e) {
          // console.error(e.message)
        }
      }
      return state.roles
    },
    routesConfig: (state) => {
      if (!state.routesConfig) {
        try {
          const routesConfig = localStorage.getItem(process.env.VUE_APP_ROUTES_KEY)
          state.routesConfig = JSON.parse(routesConfig)
          state.routesConfig = state.routesConfig ? state.routesConfig : []
        } catch (e) {
          // console.error(e.message)
        }
      }
      return state.routesConfig
    }
  },
  mutations: {
    setfreeLogin(state, payload) {
      state.freeLogin = payload
      //localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user))
    },
    setUser(state, user) {
      console.log("------3---");
      console.log(user);
      console.log("****************");
      state.user = user
      //localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user))
    },
    changLogin(state, payload) {
      state.isLogin = payload.isLogin
      console.log("------4---");
      if (payload.isLogin) {
        if (state.user) {
          state.user = {
            ...state.user,
            ...payload.userInfo
          }
          console.log("------5---");
        } else {
          state.user = payload.userInfo
          console.log("------6---");
        }
      } else {
        state.user = null
        console.log("------7---");
      }
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
      localStorage.setItem(process.env.VUE_APP_PERMISSIONS_KEY, JSON.stringify(permissions))
    },
    setRoles(state, roles) {
      state.roles = roles
      localStorage.setItem(process.env.VUE_APP_ROLES_KEY, JSON.stringify(roles))
    },
    setRoutesConfig(state, routesConfig) {
      state.routesConfig = routesConfig
      localStorage.setItem(process.env.VUE_APP_ROUTES_KEY, JSON.stringify(routesConfig))
    },
    setIsLogin(state, flag) {
      state.isLogin = flag ? true : false
    }
  },
  actions: {
    // loginFree
    loginFreeLogin(context, phoneNo) {
      return new Promise((resolve, reject) => {
        // const phoneNo = '13552181507'
        if (!phoneNo) {
          reject()
          return
        }
        loginFree(phoneNo)
          .then((res) => {
            context.commit('setIsLogin', true)
            let data = JSON.parse(res.request.response).data
            // if (data) {
            //   data.PersonalCenterShow = false
            // }
            if (data && JSON.parse(res.request.response).code == 0) {
              context.commit('setUser', data)
              resolve(res)
            }
          })
          .catch((err) => {
            context.commit('setIsLogin', false)
            reject(err)
          })
      })
    },

    checkLogin(context, payload) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('access-token')
        if (!token) {
          reject()
          return
        }

        getCurrentUserInfo()
          .then((res) => {
            context.commit('changLogin', {
              isLogin: true,
              userInfo: res
            })
            resolve(res)
          })
          .catch((err) => {
            context.commit('changLogin', {
              isLogin: false
            })
            reject(err)
          })
      })
    },
    checkThirdToken(context, payload) {
      return new Promise((resolve, reject) => {
        loginByThridToken(payload.thirdName, payload.thirdToken)
          .then((res) => {
            if (res.code == 0) {
              setLocalLogined(null, res.data.accessToken, res.data.refreshToken)

              context.commit('changLogin', {
                isLogin: true,
                userInfo: res.data
              })
            } else {
              context.commit('changLogin', {
                isLogin: false
              })
            }
            // resolve(res)
          })
          .catch((err) => {
            context.commit('changLogin', {
              isLogin: false
            })
            reject(err)
          })
      })
    }
  }
}
