import TabsView from '@/layouts/tabs/TabsView'
import BlankView from '@/layouts/BlankView'
// import PageView from "@/layouts/PageView";

// 路由配置
const options = {
  routes: [
    {
      path: '/',
      name: '搜索',
      meta: {
        icon: 'search'
      },
      component: () => import('@/pages/homePage')
    },
    // {
    //   path: "testapi",
    //   name: "test",
    //   component: () => import("@/pages/testapi"),
    // },
    {
      path: 'homePage',
      name: '搜索',
      meta: {
        icon: 'search'
      },
      component: () => import('@/pages/homePage')
    },
    {
      path: '/login',
      name: '登录',
      component: () => import('@/pages/login')
    },
    {
      path: '/forgotpwd',
      name: '忘记密码',
      component: () => import('@/pages/forgotpwd')
    },
    {
      path: '/register',
      name: '注册账号',
      component: () => import('@/pages/register')
    },

    {
      path: 'results',
      name: '结果',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/results')
    },
    {
      path: 'speech',
      name: '讲话详情',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/speech')
    },
    {
      path: 'article',
      name: '文章详情',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/article')
    },
    {
      path: 'graphic',
      name: '知识搜索',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/graphic')
    },
    {
      path: 'graphic/search',
      name: '知识搜索',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/graphic/search')
    },
    {
      path: 'branch',
      name: '支部',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/branch')
    },
    {
      path: 'branch/list',
      name: '党员一览',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/partylist')
    },
    {
      path: 'branch/announcement',
      name: '专题管理',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/announcement/index.vue')
    },

    {
      path: 'branch/practice',
      name: '实践活动',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/practice/index')
    },
    {
      path: 'branch/experience',
      name: '学习心得',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/experience/index')
    },
    {
      path: 'branch/expdetail',
      name: '学习心得详情',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/experience/detail')
    },
    {
      path: 'branch/pradetail',
      name: '实践活动详情',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/practice/detail')
    },
    {
      path: 'branch/announcementDetail',
      name: '公告详情',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/announcement/detail.vue')
    },
    {
      path: 'branch/personhome',
      name: '个人主页',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/personHome/index')
    },
    {
      path: 'branch/publishAnnouncement',
      name: '发布公告',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/announcement/add')
    },
    {
      path: 'branch/publishpra',
      name: '发布实践活动',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/practice/add')
    },
    {
      path: 'branch/publishexp',
      name: '发布学习心得',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/experience/add')
    },

    {
      path: 'question',
      name: '专家问答',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/question')
    },
    {
      path: 'user',
      name: '用户中心',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/user')
    },
    {
      path: 'agreement',
      name: '用户协议',
      meta: {
        icon: 'unordered-list'
      },
      component: () => import('@/pages/agreement')
    },
    {
      path: '/org/tree',
      name: '组织架构',
      component: () => import('@/pages/org/tree')
    },
    {
      path: '/data/center',
      name: '数据中心',
      component: () => import('@/pages/data/center')
    },
    {
      path: '/subject',
      name: '专题管理',
      component: () => import('@/pages/subject/index')
    },
    {
      path: '/subject/detail',
      name: '专题集合',
      component: () => import('@/pages/subject/detail')
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/pages/exception/404')
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/pages/exception/403')
    }

    // {
    //   path: "/",
    //   name: "首页",
    //   component: TabsView,
    //   redirect: "/homePage",
    //   children: [

    //   ],
    // },
  ]
}

export default options
