import { reqdata, METHOD } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const { POST: post, GET: get } = METHOD
const UAS_PREFIX = process.env.VUE_APP_UAS_PREFIX

async function logout(_this) {
  if (_this.$store) {
    _this.$store.commit('account/changLogin', {
      isLogin: false,
      userInfo: null
    })
  }

  reqdata(`${UAS_PREFIX}/user/logout`, post).then((data) => {
    localStorage.removeItem('access-token')
    localStorage.removeItem('refresh-token')
    localStorage.removeItem('partyOrgId')
    localStorage.removeItem('brandName')
    sessionStorage.removeItem('saveData')
    _this.$store && _this.$store.commit('account/setIsLogin', false)
  })
}

export { logout }
