import { LOGIN, ROUTES } from '@/services/api'
import { reqdata, request, METHOD, removeAuthorization } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const UAS_URL = process.env.VUE_APP_UAS_PREFIX
const UPLOAD_PREFIX = process.env.VUE_APP_UPLOAD_PREFIX
const { POST: post, GET: get } = METHOD

/**
 * 登录服务
 * @param name 账户名
 * @param password 账户密码
 * @returns {Promise<AxiosResponse<T>>}
 */
// export async function login(name, password) {
//   return request(LOGIN, METHOD.POST, {
//     name: name,
//     password: password
//   })
// }

export async function getRoutesConfig() {
  return request(ROUTES, METHOD.GET)
}

export async function getIp() {
  return reqdata(`${UAS_URL}/getIp`, get)
}
/**
 * 退出登录
 */
// export function logout() {
//   localStorage.removeItem(process.env.VUE_APP_ROUTES_KEY)
//   localStorage.removeItem(process.env.VUE_APP_PERMISSIONS_KEY)
//   localStorage.removeItem(process.env.VUE_APP_ROLES_KEY)
//   removeAuthorization()
// }

export async function uploadUserAvater(file) {
  const formData = new FormData()
  formData.append('image', file)

  return reqdata(`${UAS_URL}/user/updateAvatar`, post, formData)
}
export async function updateUserInfo(params) {
  return reqdata(`${UAS_URL}/user/updateInfo`, post, params)
}

export async function loginFree(params) {
  return request(`${UAS_URL}/user/loginFree?phoneNo=${params}`, post, params)
}

export async function getCurrentUserInfo() {
  return reqdata(`${UAS_URL}/user/info`, get)
}

export async function obtainCode(phoneNo) {
  return reqdata(`${UAS_URL}/sms/obtainCode`, get, {
    phoneNo: phoneNo
  })
}

export async function validateCode(phoneNo, smsCode, requestUID) {
  return reqdata(`${UAS_URL}/sms/validateCode`, post, {
    phoneNo: phoneNo,
    smsCode: smsCode,
    requestUID: requestUID
  })
}

export async function checkPhone(phoneNo, smsCode, requestUID) {
  return reqdata(`${UAS_URL}/user/register/checkPhone`, post, {
    phoneNo: phoneNo,
    smsCode: smsCode,
    requestUID: requestUID
  })
}

export async function updateUserPhone(params) {
  return reqdata(`${UAS_URL}/user/updatePhone`, post, params)
}

export async function updateUserPwd(params) {
  return reqdata(`${UAS_URL}/user/updatePwd`, post, params)
}

export async function resetPwd(params) {
  return reqdata(`${UAS_URL}/user/resetPwd`, post, params)
}

export async function uploadFiles(files) {
  return reqdata(`${UPLOAD_PREFIX}/uploadFeedbackAttachments`, post, files)
}
export async function putImage(files) {
  return reqdata(`${BASE_URL}/user/upload/putImage`, post, files)
}
export async function putVideo(files) {
  return reqdata(`${BASE_URL}/user/upload/putVideo`, post, files)
}

export async function uploadAttachments(files) {
  return reqdata(`${BASE_URL}/user/upload/uploadAttachment`, post, files)
}

export async function getProfile() {
  return reqdata(`${UAS_URL}/user/getProfile`, get)
}

export async function updateProfile(profile) {
  return reqdata(`${UAS_URL}/user/updateProfile`, post, profile)
}

export async function getAvatarDetail() {
  return reqdata(`${UAS_URL}/user/getAvatarDetail`, get)
}

export async function updateAvatar(imgFile) {
  const formData = new FormData()
  formData.append('image', imgFile)

  return reqdata(`${UAS_URL}/user/updateAvatar`, post, formData)
}

export async function delMyAccount(smsCode, requestUID) {
  return reqdata(`${UAS_URL}/user/delMyAccount`, post, {
    requestUID: requestUID,
    smsCode: smsCode
  })
}

export async function getAllParty(params) {
  return reqdata(`${UAS_URL}/party/getPartyAndAllChildrenParty`, post, params)
}

export async function getAllOrgs(id) {
  return reqdata(`${UAS_URL}/party/getPartyAndAllChildrenPartyByTiledDisplay`, post, {
    partyOrgId: id
  })
}
export async function addLoginTime(id) {
  return reqdata(`${UAS_URL}/durationOnline/addLoginTime`, post, {
    userUid: id
  })
}
export async function addLoginOutTime(id) {
  return reqdata(`${UAS_URL}/durationOnline/addLoginOutTime`, post, {
    userUid: id
  })
}
// 获取价格
export async function getPriceBreakdown() {
  return reqdata(`${UAS_URL}/admin/priceBreakdown/getPriceBreakdown`, post, {
    status: 1
  })
}
// 免密登录
export async function getZTUserInformation(data) {
  return reqdata(`${UAS_URL}/user/getZTUserInformation`, post, data)
}
// 去支付
export async function alipay(data) {
  return reqdata(`${UAS_URL}/admin/aliPay/alipay?amt=${data}`, get)
}
// 支付列表
export async function purchaseHistory(data) {
  return reqdata(`${UAS_URL}/admin/aliPay/purchaseHistory`, post, data)
}
// 新增支付申诉
export async function addUasPayFeedback(data) {
  return reqdata(`${UAS_URL}/admin/feedback/addUasPayFeedback`, post, data)
}
// 支付详情查询
export async function aiPayQuery(data) {
  return reqdata(`${UAS_URL}/admin/aliPay/aiPayQuery`, post, data)
}
// 删除购买记录
export async function updatePurchaseHistory(data) {
  return reqdata(`${UAS_URL}/admin/aliPay/updatePurchaseHistory`, post, data)
}
// 上传支付凭证
export async function imageUpload(data) {
  return reqdata(`${UAS_URL}/admin/aliPay/imageUpload`, post, data)
}

export default {
  // login,
  // logout,
  imageUpload,
  aiPayQuery,
  updatePurchaseHistory,
  getIp,
  alipay,
  getRoutesConfig,
  getZTUserInformation,
  addUasPayFeedback,
  purchaseHistory,
  getPriceBreakdown,
  uploadUserAvater,
  updateUserInfo,
  getCurrentUserInfo,
  obtainCode,
  validateCode,
  updateUserPhone,
  updateUserPwd,
  resetPwd,
  checkPhone,
  uploadFiles,
  getProfile,
  updateProfile,
  getAvatarDetail,
  updateAvatar,
  delMyAccount,
  getAllParty,
  getAllOrgs,
  addLoginTime,
  addLoginOutTime,
  uploadAttachments
}
