import { reqdata, METHOD } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const { POST: post, GET: get } = METHOD

// 召回查询输入相关的文章列表
async function getRelatedArticleList(params) {
  return reqdata(`${BASE_URL}/queryRelatedArticleList`, post, params)
}

async function getArticleListByScroll(scrollid, qsterms) {
  return reqdata(`${BASE_URL}/searchArticleListByScroll`, post, {
    scrollid: scrollid,
    qsterms: qsterms
  })
}

async function getRelatedPictureList(params) {
  return reqdata(`${BASE_URL}/queryRelatedSpeechImageList`, post, params)
}

async function getPictureListByScroll(scrollid, qsterms) {
  return reqdata(`${BASE_URL}/searchSpeechImageListByScroll`, post, {
    scrollid: scrollid,
    qsterms: qsterms
  })
}

async function getSpeechDetail(speechuid) {
  return reqdata(`${BASE_URL}/getSpeechDetail`, post, {
    speechuid: speechuid
  })
}

async function getSpeechRecommandSpeechList(speechuid) {
  return reqdata(`${BASE_URL}/getSpeechRecommandSpeechList`, post, {
    speechuid: speechuid
  })
}

async function getSpeechRecommandArticleList(speechuid, doccategory) {
  return reqdata(`${BASE_URL}/getSpeechRecommandArticleList`, post, {
    speechuid: speechuid,
    doccategory: doccategory
  })
}

async function getArticleDetail(docuid) {
  return reqdata(`${BASE_URL}/getArticleDetail`, post, {
    docuid: docuid
  })
}
async function searchQuestionListByScroll(params) {
  return reqdata(`${BASE_URL}/searchQuestionListByScroll`, post, params)
}

async function getArticleRecommandSpeechList(docuid) {
  return reqdata(`${BASE_URL}/getArticleRecommandSpeechList`, post, {
    docuid: docuid
  })
}

async function getArticleRecommandArticleList(docuid, doccategory) {
  return reqdata(`${BASE_URL}/getArticleRecommandArticleList`, post, {
    docuid: docuid,
    doccategory: doccategory
  })
}

async function searchKeywords(qs) {
  return reqdata(`${BASE_URL}/searchKeywords`, post, {
    qs: qs
  })
}

async function getRelatedKeywords(uid) {
  return reqdata(`${BASE_URL}/getRelatedKeywords`, post, {
    uid: uid
  })
}

async function getRelatedSpeechList(params) {
  return reqdata(`${BASE_URL}/queryRelatedSpeechList`, post, params)
}

async function getSpeechGroupByMeeting(params) {
  return reqdata(`${BASE_URL}/getSpeechGroupByMeeting`, post, params)
}

async function getSpeechGroupByLocation(params) {
  return reqdata(`${BASE_URL}/getSpeechGroupByLocation`, post, params)
}

async function getSpeechGroupByOrg(params) {
  return reqdata(`${BASE_URL}/getSpeechGroupByOrg`, post, params)
}

async function getGoldsentList(params) {
  return reqdata(`${BASE_URL}/queryGoldsentList`, post, params)
}

async function getAllusionList(params) {
  return reqdata(`${BASE_URL}/queryAllusionList`, post, params)
}

async function getRelatedQuestionList(params) {
  return reqdata(`${BASE_URL}/queryRelatedQuestionList`, post, params)
}

async function getQuestionDetail(uid) {
  return reqdata(`${BASE_URL}/getQuestionDetail`, post, {
    uid: uid
  })
}

async function getQuestionRecommandArticleList(qauid, doccategory) {
  return reqdata(`${BASE_URL}/getQuestionRecommandArticleList`, post, {
    qauid: qauid,
    doccategory: doccategory
  })
}

async function getQuestionRecommandSpeechList(qauid) {
  return reqdata(`${BASE_URL}/getQuestionRecommandSpeechList`, post, {
    qauid: qauid
  })
}

async function getSpeechListBySubject(params) {
  return reqdata(`${BASE_URL}/getSpeechListBySubject`, post, params)
}

async function searchSpeechByScroll(type, scrollid, qsterms) {
  return reqdata(`${BASE_URL}/searchSpeechByScroll`, post, {
    type: type,
    scrollid: scrollid,
    qsterms: qsterms
  })
}

async function getKGOutlineListBySubject(subjectuid, sort, kgtypes) {
  return reqdata(`${BASE_URL}/getKGOutlineListBySubject`, post, {
    subjectuid: subjectuid,
    sort: sort,
    kgtypes: kgtypes
  })
}

async function getSpeechArticleCategoryList() {
  return reqdata(`${BASE_URL}/getSpeechArticleCategoryList`, post)
}

async function getSuggestKeywords(qs) {
  return reqdata(`${BASE_URL}/getSuggestKeywords`, post, {
    qs: qs
  })
}

async function getAiuiConfig(qs) {
  return reqdata(`${BASE_URL}/config/getAiuiConfig`, post)
}

export {
  getRelatedArticleList,
  getSpeechDetail,
  getSpeechRecommandSpeechList,
  getSpeechRecommandArticleList,
  getArticleDetail,
  getArticleRecommandSpeechList,
  getArticleRecommandArticleList,
  searchKeywords,
  getRelatedKeywords,
  getRelatedSpeechList,
  getSpeechGroupByMeeting,
  getSpeechGroupByLocation,
  getSpeechGroupByOrg,
  getGoldsentList,
  getAllusionList,
  getArticleListByScroll,
  getRelatedQuestionList,
  getQuestionDetail,
  getQuestionRecommandArticleList,
  getQuestionRecommandSpeechList,
  getSpeechListBySubject,
  searchSpeechByScroll,
  getSpeechArticleCategoryList,
  getKGOutlineListBySubject,
  getSuggestKeywords,
  getAiuiConfig,
  searchQuestionListByScroll,
  getRelatedPictureList,
  getPictureListByScroll
}
