import enquireJs from 'enquire.js'

export function isDef(v) {
  return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove(arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item)
    if (index > -1) {
      return arr.splice(index, 1)
    }
  }
}

export function isRegExp(v) {
  return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
  const handler = {
    match: function () {
      call && call(true)
    },
    unmatch: function () {
      call && call(false)
    }
  }
  enquireJs.register('only screen and (max-width: 767.99px)', handler)
}

export function isLogin(_this) {
  var result = validateStoreLogin(_this)

  return result
}

function validateStoreLogin(_this) {
  var isLogin = _this.$store.getters['account/isLogin']
  var hasUInfo = !_.isEmpty(_this.$store.getters['account/user'])
  var accessToken = !_.isEmpty(localStorage.getItem('access-token'))
  if (isLogin && hasUInfo && accessToken) {
    return true
  } else {
    return false
  }
}

export function formateDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

export function createArticleCategories(excludeAll) {
  var categories = []
  if (!excludeAll) {
    var item = { id: 0, scrollid: 0, orderby: 0, sort: 0, name: '全部', articles: [] }
    categories.push(item)
  }

  var item2 = { id: 2, scrollid: 0, orderby: 0, sort: 0, name: '讲话原文', articles: [] }
  categories.push(item2)

  var item3 = { id: 3, scrollid: 0, orderby: 0, sort: 0, name: '理论述评', articles: [] }
  categories.push(item3)

  var item4 = { id: 4, scrollid: 0, orderby: 0, sort: 0, name: '理论解读', articles: [] }
  categories.push(item4)

  var item5 = { id: 5, scrollid: 0, orderby: 0, sort: 0, name: '实践述评', articles: [] }
  categories.push(item5)

  var item6 = { id: 6, scrollid: 0, orderby: 0, sort: 0, name: '实践报道', articles: [] }
  categories.push(item6)

  var item7 = { id: 7, scrollid: 0, orderby: 0, sort: 0, name: '政策措施', articles: [] }
  categories.push(item7)

  var item9 = { id: 9, scrollid: 0, orderby: 0, sort: 0, name: '核心报道', articles: [] }
  categories.push(item9)

  return categories
}

// 左边补0函数
function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function gotoNewPage(router, path, params) {
  let routeData = router.resolve({ path: path, query: params })
  window.open(routeData.href, '_blank')
}

export function setLocalLogined(_this, accessToken, refreshToken, partyOrgId, brandName) {
  localStorage.setItem('access-token', accessToken)
  localStorage.setItem('refresh-token', refreshToken)
  if (partyOrgId) {
    localStorage.setItem('partyOrgId', partyOrgId)
  }
  if (brandName) {
    localStorage.setItem('brandName', brandName)
  }

  if (_this) {
    _this.$store.commit('account/setIsLogin', true)
  }
}

export function getLocalUser(_this) {
  var user = _this.$store.getters['account/user']

  return user
}

export function setLocalUser(_this, userInfo) {
  // if (userInfo) {
  //   userInfo.PersonalCenterShow = true
  // }
  _this.$store.commit('account/setUser', userInfo)
}

export function filterHtml(str) {
  var reg = new RegExp('<[^>]+>', 'gi')
  return str.replace(reg, '')
}

export function testPwdValue(value, callback) {
  // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
  const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%^&*)(_+}{|:?><]).{8,16}$/
  if (reg.test(value)) {
    callback()
  } else {
    callback('请输入8-16位密码，必须由数字、特殊字符、大写字母和小写字母组合')
  }
}

// export function getLength(str) {
//   if (!str) {
//     return 0
//   }
//   let len = value.replace(/[\u0391-\uFFE5]/g, 'aa').length
//   return len
// }
export function clearRichText(texts) {
  let text1 = texts.replace(/<\/?(img|video|iframe)[^>]*>/g, '')
  let text2 = text1.replaceAll('<p><br/></p>', '')
  return text2
}
export function getMediaTop(str) {
  // 截取富文本img方法
  var data = []
  str.replace(/<(img|video|iframe)[^>]*>/g, function (match, capture) {
    data.push(match)
  })

  return data
}

const _toString = Object.prototype.toString
