<template>
  <div class="systemLogo" @click="skip">
    <img src="@/assets/common/logo.png" alt="" />
    <div class="xinhuashe-sig" v-if="showBrand"><span>新华社</span></div>
    <div class="qiangjun-version" v-if="showQJVersion"><span>强军版</span></div>
  </div>
</template>
<script>
  export default {
    props: {
      height: {
        type: String,
        default: () => {
          return '40px'
        }
      },
      width: {
        type: String,
        default: () => {
          return '150px'
        }
      },
      showBrand: {
        type: Boolean,
        default: () => {
          return false
        }
      }
    },
    name: 'systemName',
    data() {
      return {
        showQJVersion: false
      }
    },
    inject: ['reload'],
    created() {
      let subdomain = this.getSubdomain();
      if(subdomain == "wendao-qiangjun"){
        this.showQJVersion = true;
      }
    },
    methods: {
      getSubdomain() {
        // 获取完整的主机名，例如：subdomain.example.com
        var fullHostname = window.location.hostname

        // 使用正则表达式匹配子域名部分
        var subdomainMatch = fullHostname.match(/^(.*?)\./)

        // 如果匹配成功，返回子域名；否则，返回null
        return subdomainMatch ? subdomainMatch[1] : null
      },
      skip() {
        // console.log(this.$route);
        const { path } = this.$route
        if (path === '/homePage') {
          this.reload()
        } else {
          this.routerSkip(this.$router, 1)
        }
      }
    },
    computed: {}
  }
</script>
<style scoped lang="less">
  .systemLogo {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    margin-right: 50px;
    margin-bottom: 8px;
    img {
      height: 46px;
      width: 150px;
    }
  }
  .xinhuashe-sig,
  .qiangjun-version {
    font-size: 14px;
    color: #fff;
    margin-left: 20px;
    text-align: center;
  }
  .xinhuashe-sig span,
  .qiangjun-version {
    border: 1px solid #fff;
    border-radius: 2px;
    width: 60px;
    height: 24px;
    display: inline-block;
  }
</style>
