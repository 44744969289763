<template>
  <div class="global-search-wrapper" :class="{ theHeightStyle: isLarger, theMediumtStyle: isMedium }">
    <a-auto-complete
      v-model="inputData"
      ref="autoCompleteRef"
      class="global-search"
      size="large"
      style="width: 100%"
      placeholder="知所未知、见所未见，请在这里输入您的问题"
      @select="getSelect"
      @search="getSearch"
      :defaultActiveFirstOption="false"
      @focus="atFocus"
      @blur="atBlur"
    >
      <template slot="dataSource">
        <a-select-opt-group v-for="(items, key) in dataSource" :key="key">
          <span class="recommend-title" slot="label"><i class="recommend-icon"></i><label>智能推荐</label></span>
          <a-select-option v-for="(item, index) in items.value" :key="index + 100 + ''" :title="item.label">
            <div class="dataSourceRow" :title="item.label">
              <img src="@/assets/home/icon-question.png" alt="" />
              {{ item.label }}
            </div>
          </a-select-option>
        </a-select-opt-group>
      </template>
      <!-- @keypress="getEnter" -->
      <template slot="default">
        <!-- :allowClear="true" -->
        <a-input :autosize="true" @keyup="getEnter">
          <template slot="prefix">
            <i class="owl-icon"></i>
          </template>
          <template slot="suffix">
            <div class="search-suffix">
              <SoundInput @finishRecog="finishRecog"></SoundInput>
              <a-button class="searchButton" slot="suffix" icon="search" size="large" type="primary" @click="skipNewPage">
                <Tooltip ref="tooltipRef">
                  {{ buttonText }}
                </Tooltip>
              </a-button>
            </div>
          </template>
        </a-input>
      </template>
    </a-auto-complete>
    <div class="search-type-wrap">
      <a-radio-group v-model:value="searchType" name="searchType">
        <a-radio :value="0">语义</a-radio>
        <a-radio :value="1">精确</a-radio>
      </a-radio-group>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { mapMutations } from 'vuex'
  import { homePage } from '@/utils/api'
  import SoundInput from '@/components/soundinput'
  import Tooltip from '@/components/tooltip'
  import { encrypt, decode } from '@/utils/encrypt'

  export default {
    components: { Tooltip, SoundInput },
    props: {
      words: {
        type: String,
        default: () => {
          return ''
        }
      },
      isSearch: {
        type: Boolean,
        default: () => {
          return true // 如果为false 的情况下，就不过滤数据源
        }
      },
      isLarger: {
        type: Boolean,
        default: () => {
          return false // 是否为更大号
        }
      },
      isMedium: {
        type: Boolean,
        default: () => {
          return false // 是否为中号
        }
      }
    },
    name: 'SearchBox',
    inject: ['reload'],
    data() {
      return {
        inputData: '',
        // 搜索联想数据源
        dataSource: [],
        //   输入框的值
        //   按钮文字
        buttonText: '问一下',
        searchType: 0,
        //   防抖调用接口
        unShakeGetDataSource: _.debounce(this.getDataSource, 200)
      }
    },
    created() {
      //  window.addEventListener('keydown', this.getEnter);
    },
    methods: {
      ...mapMutations('search', ['setKeyText', 'setRefresh']),
      // 获取输入框内的值
      clear() {
        this.inputData = ''
      },
      // keyDown(e){
      //     if(e.keyCode == 13){
      //       this.getData();
      //     }
      // },
      // 这里为搜索联想的接口，异步函数，防抖控制触发频率
      async getDataSource() {
        const is = this.inputData ? await this.checkAfterSkip() : true
        if (!is) return false
        try {
          this.dataSource = []
          if (!this.inputData) return
          const { getQueryAutoQuestion } = homePage
          getQueryAutoQuestion(this.inputData).then((data) => {
            let arr = []
            _(data).forEach((item) => {
              arr.push({
                key: item.quid + '',
                label: item.question
              })
            })

            this.dataSource = [
              {
                value: _.cloneDeep(arr)
              }
            ]
          })
        } catch (error) {}
      },

      // 离开焦点触发
      atBlur() {
        this.dataSource = []
      },

      atFocus() {
        this.unShakeGetDataSource()
      },

      // 按键触发函数
      getEnter(e) {
        // console.log("enter", e);
        const trigger = 'Enter'
        const { keyCode, code, target } = e
        if (trigger === code && keyCode === 13) {
          setTimeout(() => {
            this.skipNewPage()
          }, 300)
        }
      },

      async checkAfterSkip() {
        if (!this.inputData) {
          this.$refs.tooltipRef.open('请输入您的问题')
          return false
        }
        const { queryCensor } = homePage
        const res = await queryCensor(this.inputData)
        if (res) {
          return true
        } else {
          // this.$message.error("你输入的内容包含违禁词!");
          try {
            this.$refs.tooltipRef.open()
          } catch (error) {}
          return false
        }
      },

      finishRecog(text) {
        this.inputData = text
        this.skipNewPage()
      },

      async skipNewPage() {
        const is = await this.checkAfterSkip()
        if (is === true) {
          try {
            if (!this.inputData) {
              return
            }
            this.dataSource = []
            const { history } = this.$router
            const { current } = history
            const { path } = current
            if (path === '/results') {
              const query = _.cloneDeep(this.$route.query)
              if (query.qs !== this.inputData) {
                query.qs = this.inputData
                this.$router.push({
                  path: '/results',
                  query: { qs: encrypt(query.qs), type: this.searchType }
                })
                // this.routerSkip(this.$router, 2, query.qs+"&type="+this.searchType);
                // this.skip(query.qs);
                this.$refs.autoCompleteRef.blur()
                this.$refs.autoCompleteRef.focus()
                this.$refs.autoCompleteRef.blur()
              }
            } else {
              sessionStorage.setItem('search-handle-inhome', 1)
              this.$router.push({
                path: '/results',
                query: { qs: encrypt(this.inputData), type: this.searchType }
              })
              // this.routerSkip(this.$router, 2, this.inputData+"&type="+this.searchType);
            }
          } catch (e) {}
        } else if (is === false) {
          return false
        }
      },

      // skip(qs) {
      //   this.$router.push({
      //     path: "/results",
      //     query: { qs: encrypt(qs) },
      //   });
      // },

      // 搜索补全时触发
      getSearch(e) {
        // console.log("search", e);
        // this.changeKeyText(e);
        if (!this.isSearch) return false
        this.unShakeGetDataSource()
      },

      // 选择时触发
      getSelect(e, k) {
        // console.log("选择 ", e, k.componentOptions?.propsData?.title);

        try {
          this.inputData = k.componentOptions?.propsData?.title + ''
          setTimeout(() => {
            this.dataSource = []
            this.skipNewPage()
          }, 300)
        } catch (error) {}
      },

      // 修改关键文本
      changeKeyText(data) {
        this.setKeyText(data)
        // this.$nextTick(() => {
        //   sessionStorage.setItem("keyText", data);
        // });
      }
    },
    watch: {
      words: {
        immediate: true,
        handler(val) {
          this.inputData = val
        }
      },
      inputData: {
        immediate: true,
        handler(val) {
          this.setKeyText(val)
        }
      }
    }
  }
</script>
<style scoped lang="less">
  @import url('index.less');

  .recommend-icon {
    display: inline-block;
    margin-right: 5px;
    margin-left: 8px;
    width: 14px;
    height: 14px;
    background: url('~@/assets/common/recommend-icon.svg') no-repeat;
  }

  .recommend-title {
    display: flex;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    letter-spacing: 0;
    line-height: 14px;
  }

  .search-type-wrap {
    margin: 10px 15px 0 0;
    text-align: right;
  }
</style>
