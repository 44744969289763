module.exports = {
  messages: {
    CN: {
      home: { name: '首页' }
    },
    US: {
      home: { name: 'home' }
    },
    HK: {
      home: { name: '首頁' },
      demo: {
        name: '演示頁'
      },
      parent1: {
        name: '父級路由1',
        demo: { name: '演示頁面1' }
      },
      parent2: {
        name: '父級路由2',
        demo: { name: '演示頁面2' }
      },
      exception: {
        name: '異常頁',
        404: { name: '404' },
        403: { name: '403' },
        500: { name: '500' }
      }
    }
  }
}
