export default {
  namespaced: true,
  state: {
    keyText: '',
    searchBoxShow: true,
    refresh: true,
    searchTime: 0,
    xunfeiConfig: null
  },
  mutations: {
    setSearchTime(state, data) {
      state.searchTime = data
    },
    setKeyText(state, data) {
      state.keyText = data
    },
    setSearchBoxShow(state, data) {
      state.searchBoxShow = data
    },
    setRefresh(state, data) {
      state.refresh = data
    },
    setXunfeiConfig(state, data) {
      state.xunfeiConfig = data
    }
  }
}
