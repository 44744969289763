import Cookie from 'js-cookie'
import _ from 'lodash'
import { newLoginServices } from '@/services'
import { initRouter } from '../router'
const router = initRouter()
const UAS_PREFIX = process.env.VUE_APP_UAS_PREFIX

// 401拦截
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  async onFulfilled(response, options) {
    return response
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  async onRejected(error, options) {
    const { config, status } = error.response
    const { url } = config
    const { message } = options
    if (status == 401) {
      if (url === `${UAS_PREFIX}/oauth/refreshToken`) {
        // router.push("login");
        // message.error("登录失效,请重新登录！");
        // setTimeout(() => {
        //   location.reload();
        // }, 1000);
        //refreshToken过期，需要重新登录

        return Promise.resolve({ code: 300 })
      } else {
        const { refreshToken: api } = newLoginServices
        const res = await api()
        if (!res || res.code) {
          return Promise.resolve({ code: res.code })
        }
        const { accessToken, refreshToken, partyOrgId, brandName } = res
        if (accessToken) {
          localStorage.setItem('access-token', accessToken)
          localStorage.setItem('refresh-token', refreshToken)
          // localStorage.setItem('partyOrgId', partyOrgId)
          // localStorage.setItem('brandName', brandName)
          // return Promise.reject(error);
          return Promise.resolve({ code: 100 }) //重试
        } else {
          return Promise.reject(error)
        }
      }
    } else {
      return Promise.reject(error)
    }
  }
}

// const resp403 = {
//   onFulfilled(response, options) {
//     const { message } = options;
//     if (response.code === 403) {
//       // message.error("请求被拒绝");
//     }
//     return response;
//   },
//   onRejected(error, options) {
//     const { message } = options;
//     const { response } = error;
//     if (response.status === 403) {
//       // message.error("请求被拒绝");
//     }
//     return Promise.reject(error);
//   },
// };

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    const noTokenList = [
      `${UAS_PREFIX}/user/login`,
      `${UAS_PREFIX}/user/login/mobile`,
      `${UAS_PREFIX}/sms/obtainCode`,
      `${UAS_PREFIX}/sms/validateCode`,
      `${UAS_PREFIX}/user/registerMobile`,
      `${UAS_PREFIX}/user/register`,
      `${UAS_PREFIX}/oauth/authorize`
    ]

    const refreshTokenUrl = `${UAS_PREFIX}/oauth/refreshToken`

    const { message } = options
    const { url, xsrfCookieName } = config
    if (_.indexOf(noTokenList, url) == -1) {
      if (url === refreshTokenUrl) {
        const RefreshToken = localStorage.getItem('refresh-token')
        config.headers['refreshToken'] = RefreshToken
      } else {
        const token = localStorage.getItem('access-token')
        if (token) {
          config.headers['Authorization'] = token
        }
      }
    }
    // if (
    //   url.indexOf("login") === -1 &&
    //   xsrfCookieName &&
    //   !Cookie.get(xsrfCookieName)
    // ) {
    //   message.warning("认证 token 已过期，请重新登录");
    // }
    return config
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options
    // message.error(error.message);
    return Promise.reject(error)
  }
}

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401] // 响应拦截
}
