class SoundRecorder {
  constructor(opts = {}) {
    this.wsurl = opts.wsurl || ''
    this.originurl = opts.originurl || ''
    this.message = opts.message
    this.onTextChange = opts.onTextChange || Function()
    this.onSocketClosed = opts.onSocketClosed || Function()
    // 方言/语种
    this.status = 'null'
    // 流媒体
    this.streamRef = []
    // 记录音频数据
    this.audioData = []
    // 记录听写结果
    this.resultText = ''
    this.endFlag = '--end--'
  }
  init(onGetMediaSuccess) {
    const self = this
    try {
      self.initRecorder(onGetMediaSuccess)
      self.webWorker = new Worker('/transcode.worker.js')
      self.webWorker.onmessage = function (event) {
        self.audioData.push(...event.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  createSocket() {
    this.websock = new WebSocket(this.wsurl)
    var self = this
    this.websock.onopen = function () {
      self.websocketOnOpen()
    }
    this.websock.onmessage = function (e) {
      self.websocketOnMessage(e)
    }
    this.websock.onerror = function (e) {
      self.websocketOnError(e)
    }
    this.websock.onclose = function (e) {
      self.websocketClose(e)
    }
  }
  websocketOnOpen() {
    console.log('连接成功')
    setTimeout(() => {
      this.webSocketSend()
    }, 500)
  }
  webSocketSend() {
    if (this.websock.readyState !== 1) return false
    if (this.audioData.length > 0) {
      const audioData = this.audioData.splice(0, 1280)
      this.websock.send(this.toBytes(audioData))
    }

    this.handlerInterval = setInterval(() => {
      // websocket未连接
      if (this.websock.readyState !== 1) {
        this.audioData = []
        clearInterval(this.handlerInterval)
        return false
      }
      if (this.audioData.length === 0) {
        if (this.status === 'end') {
          var endBytes = this.toBytes(this.toUint8Arr(this.endFlag))
          this.websock.send(endBytes)

          this.audioData = []
          clearInterval(this.handlerInterval)
        }
        return false
      }
      const audioData = this.audioData.splice(0, 1280)
      this.websock.send(this.toBytes(audioData))
    }, 40)
  }
  websocketOnMessage(e) {
    var result = JSON.parse(e.data)
    if (result.data.text) {
      this.setResultText(result.data.text)
    }
  }
  websocketOnError(e) {
    console.log(`连接失败的信息：`, e)
  }
  websocketClose(e) {
    if (this.status != 'end') {
      this.onSocketClosed()
    }

    console.log('断开连接', e)
  }
  initRecorder(onGetMediaSuccess) {
    // 创建音频环境
    try {
      this.audioContext = this.audioContext ? this.audioContext : new (window.AudioContext || window.webkitAudioContext)()
      this.audioContext.resume()
      if (!this.audioContext) {
        this.message.warning('浏览器不支持webAudioApi相关接口')
        return false
      }
    } catch (e) {
      if (!this.audioContext) {
        this.message.warning('浏览器不支持webAudioApi相关接口')
        return false
      }
    }
    // 获取浏览器录音权限成功时回调
    let getMediaSuccess = (_) => {
      this.scriptProcessor = this.audioContext.createScriptProcessor(0, 1, 1)
      this.scriptProcessor.onaudioprocess = (e) => {
        if (this.status === 'ing') {
          try {
            this.webWorker.postMessage(e.inputBuffer.getChannelData(0))
          } catch (error) {}
        }
      }
      // 创建一个新的MediaStreamAudioSourceNode 对象，使来自MediaStream的音频可以被播放和操作
      this.mediaSource = this.audioContext.createMediaStreamSource(this.streamRef)
      this.mediaSource.connect(this.scriptProcessor)
      this.scriptProcessor.connect(this.audioContext.destination)
      if (onGetMediaSuccess) {
        onGetMediaSuccess()
      }
      //this.createSocket();
    }
    // 获取浏览器录音权限失败时回调
    let getMediaFail = (e) => {
      this.message.error('请求麦克风失败')
      this.audioContext && this.audioContext.close()
      this.audioContext = undefined
      // 关闭websocket
      if (this.webSocket && this.webSocket.readyState === 1) {
        this.webSocket.close()
      }
    }
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
    // 获取浏览器录音权限
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true
        })
        .then((stream) => {
          this.streamRef = stream
          getMediaSuccess()
        })
        .catch((e) => {
          console.log(e)
          getMediaFail(e)
        })
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia(
        {
          audio: true
        },
        (stream) => {
          this.streamRef = stream
          getMediaSuccess()
        },
        function (e) {
          getMediaFail(e)
        }
      )
    } else {
      if (navigator.userAgent.toLowerCase().match(/chrome/) && location.origin.indexOf('https://') < 0) {
        console.error('获取浏览器录音功能，因安全性问题，需要在localhost 或 127.0.0.1 或 https 下才能获取权限！')
      } else {
        this.message.warning('对不起：未识别到录音设备!')
      }
      this.audioContext && this.audioContext.close()
      return false
    }
  }

  setStatus(status) {
    this.status = status
  }

  setResultText(resultText) {
    if (this.status == 'end') {
      return
    }
    this.onTextChange && this.onTextChange(resultText)
  }

  toBytes(buffer) {
    let bytes = new Uint8Array(buffer)
    return bytes
  }

  toUint8Arr(myString) {
    return new TextEncoder('utf-8').encode(myString)
  }

  recorderStart() {
    this.setStatus('ing')
    this.audioContext.resume()
  }

  // 停止录音
  recorderStop() {
    this.audioContext && this.audioContext.suspend()
    this.setStatus('end')
  }

  // 开始
  start() {
    if (!this.audioContext) {
      return
    }
    this.createSocket()
    this.recorderStart()
    // this.webSocketSend();
  }

  // 停止
  stop() {
    this.recorderStop()
  }

  destroy() {
    if (this.status != 'end') {
      this.stop()
    }
    this.audioContext && this.audioContext.close()
  }
}

export { SoundRecorder }
