import _ from 'lodash'
import { homePageService, resultsService, detailsService, loginService, recommendService } from '@/services'

// 搜索页面

// 反馈
function newFeedback(params) {
  return new Promise((resolve, reject) => {
    homePageService
      .newFeedback(params)
      .then((res) => {
        const { code, data, msg } = res.data
        if (code === 0) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
      .catch((err) => {
        reject(false)
      })
  })
}

// 纠错
// function newErrcorrect(content, srctype, srcuid, srctext, contact) {
//   return new Promise((resolve, reject) => {
//     homePageService
//       .newErrcorrect(content, srctype, srcuid, srctext, contact)
//       .then((res) => {
//         const { code, data, msg } = res.data;
//         if (code === 0) {
//           resolve(true);
//         } else {
//           resolve(false);
//         }
//       })
//       .catch((err) => {
//         reject(false);
//       });
//   });
// }

// 搜索审查
function queryCensor(qs) {
  return new Promise((resolve, reject) => {
    homePageService
      .queryCensor(qs)
      .then((res) => {
        const { code, data, msg } = res.data
        if (code === 0) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
      .catch((err) => {
        reject(false)
      })
  })
}

// 热点
function getHotQuestions() {
  return new Promise((resolve, reject) => {
    homePageService.getHotQuestions().then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 搜索联想
function getQueryAutoQuestion(qs) {
  return new Promise((resolve, reject) => {
    homePageService.getQueryAutoQuestion(qs).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// results

// 获取知识图谱数据
// getRelatedKeywords;
function getRelatedKeywords(uid) {
  return new Promise((resolve, reject) => {
    resultsService.getRelatedKeywords(uid).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 一次性搜索;

function searchAll(qs, swname, swuid, qi, type) {
  return new Promise((resolve, reject) => {
    resultsService.searchAll(qs, swname, swuid, qi, type).then((res) => {
      // console.log("searchall", res);
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve({})
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 脉络
// 游标加载

// function searchSpeechByScroll(type, id) {
//   return new Promise((resolve, reject) => {
//     resultsService.searchSpeechByScroll(type, id).then((res) => {
//       const { code, data, msg } = res.data;
//       if (code !== 0) {
//         reject(res.data);
//       } else {
//         if (_.isNil(data) || _.isNull(data)) {
//           resolve([]);
//         } else {
//           resolve(data);
//         }
//       }
//     });
//   });
// }

function getTopicRelatedArticles(params) {
  return new Promise((resolve, reject) => {
    resultsService.getTopicRelatedArticles(params).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 底部问题推荐
function getSearchRelatedQuestions(qs) {
  return new Promise((resolve, reject) => {
    resultsService.getSearchRelatedQuestions(qs).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 结果页面
// 获取文章详情
function getArticleDetail(docuid) {
  return new Promise((resolve, reject) => {
    detailsService.getArticleDetail(docuid).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 获取主题词
function getArticleKeywords(docuid) {
  return new Promise((resolve, reject) => {
    detailsService.getArticleKeywords(docuid).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 获取文章锚点
function getArticleSpeechList(docuid) {
  return new Promise((resolve, reject) => {
    detailsService.getArticleSpeechList(docuid).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 相关推荐
function getRecommendArticles(docuid) {
  return new Promise((resolve, reject) => {
    detailsService.getRecommendArticles(docuid).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve([])
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 登录
function systemLogin(id, pwd) {
  return new Promise((resolve, reject) => {
    loginService.systemLogin(id, pwd).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve(false)
        } else {
          resolve(data)
        }
      }
    })
  })
}

// 检查登录
function checkLogin() {
  return new Promise((resolve, reject) => {
    loginService
      .checkLogin()
      .then((res) => {
        const { code } = res.data
        if (code === 50) {
          reject(true)
        } else {
          resolve(false)
        }
      })
      .catch((err) => {
        resolve(err)
      })
  })
}

// // 收藏列表
// getfavlist
function getfavlist(page) {
  return new Promise((resolve, reject) => {
    loginService.getfavlist(page).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve(false)
        } else {
          resolve(data)
        }
      }
    })
  })
}
// // 添加收藏
// addfav,
function addfav(obj) {
  return new Promise((resolve, reject) => {
    loginService.addfav(obj).then((res) => {
      const { code, data, msg } = res.data
      if (code !== 0) {
        reject(res.data)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve(false)
        } else {
          resolve(data)
        }
      }
    })
  })
}

// // 删除收藏
// delfav,
function delfav(id) {
  return new Promise((resolve, reject) => {
    loginService.delfav(id).then((res) => {
      // console.log("delfav", res);
      const { code, data, status } = res
      if (status !== 200) {
        reject(res)
      } else {
        if (_.isNil(data) || _.isNull(data)) {
          resolve(false)
        } else {
          resolve(data)
        }
      }
    })
  })
}

// ------------------------------------------------
const homePage = {
  getHotQuestions,
  getQueryAutoQuestion,
  queryCensor,
  newFeedback
  // newErrcorrect,
}

const results = {
  searchAll,
  getTopicRelatedArticles,
  getSearchRelatedQuestions,
  // searchSpeechByScroll,
  getRelatedKeywords
}

const details = {
  getArticleDetail,
  getArticleKeywords,
  getArticleSpeechList,
  getRecommendArticles
}

const user = {
  systemLogin,
  checkLogin,
  getfavlist,
  addfav,
  delfav
}

export { homePage, results, details, user }
