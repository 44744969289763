<template>
  <div @click="isTimeOut">
    <a-config-provider :locale="locale" :get-popup-container="popContainer">
      <router-view v-if="isShow" />
    </a-config-provider>
  </div>
</template>

<script>
  import { enquireScreen, setLocalLogined, isLogin, setLocalUser } from './utils/util'
  import { mapState, mapMutations, mapGetters } from 'vuex'
  // import themeUtil from '@/utils/themeUtil'
  import { getI18nKey } from '@/utils/routerUtil'
  import moment from 'moment'
  import { logout } from '@/services/auth'
  import { message } from 'ant-design-vue'
  import { getIp, addLoginTime, addLoginOutTime, getZTUserInformation } from '@/services/user'
  export default {
    name: 'App',
    data() {
      return {
        locale: {},
        isShow: true,
        lastTime: null,
        timeOut: 60 * 1000 * 60
      }
    },
    provide() {
      return {
        reload: this.reload
      }
    },
    computed: {
      ...mapGetters('account', ['user'])
    },
    beforeCreate() {},
    destroyed() {
      if (this.$store.state.account.user?.useruid) this.inFun()
    },
    created() {
      this.inFun()
      // this.locationFun() //===
      // setLogin
      this.setHtmlTitle()
      this.setLanguage(this.lang)
      enquireScreen((isMobile) => this.setDevice(isMobile))
      //this.autoLogin();
      this.lastTime = new Date().getTime()

      // , this.$store, this.$route.query?.phoneNo
      // if (!this.user?.accessToken && this.GetUrlParam()) { // ===
      //   this.$store.dispatch('account/loginFreeLogin', this.GetUrlParam()).then((res) => {
      //     if (JSON.parse(res.request.response).code == 0) {
      //       const { accessToken, refreshToken, partyOrgId, brandName } = JSON.parse(res.request.response).data
      //       localStorage.setItem('access-token', accessToken)
      //       localStorage.setItem('refresh-token', refreshToken)
      //       localStorage.setItem('partyOrgId', partyOrgId)
      //       localStorage.setItem('brandName', brandName)
      //     }
      //   })
      // }

      let result = this.checkThrirdToken()
      if (!result) {
        this.$store
          .dispatch('account/checkLogin')
          .then(() => {
            this.reportPageTime()
          })
          .catch((err) => {
            localStorage.removeItem('__page_open_time__')
            localStorage.removeItem('__page_close_time__')
          })
      }
      if (this.getParam('phone')) {
        this.unPassWorld()
      }
    },
    mounted() {
      //  兼容ie的路由跳转
      function checkIE() {
        return '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style
      }
      if (checkIE()) {
        window.addEventListener(
          'hashchange',
          () => {
            const currentPath = window.location.hash.slice(1)
            if (this.$route.path !== currentPath) {
              this.$router.push(currentPath)
            }
          },
          false
        )
      }
    },

    watch: {
      lang(val) {
        this.setLanguage(val)
        this.setHtmlTitle()
      },
      $route() {
        this.setHtmlTitle()
      },
      // 'theme.mode': function (val) {
      //   let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      //   themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
      // },
      // 'theme.color': function (val) {
      //   let closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      //   themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
      // },
      layout: function () {
        window.dispatchEvent(new Event('resize'))
      }
    },
    computed: {
      ...mapState('setting', ['layout', 'theme', 'lang'])
    },
    methods: {
      ...mapMutations('setting', ['setDevice']),
      // 免密登录接口
      unPassWorld() {
        const param = this.getParamsFromUrl()
        getZTUserInformation(param)
          .then((res) => {
            console.log(res)
            const { accessToken, refreshToken, partyOrgId, brandName } = res
            if (accessToken) {
              this.setLoginSuccess(accessToken, refreshToken, res, partyOrgId, brandName)
            } else {
              this.$message.error(`${res}!`)
            }
          })
          .catch((ref) => {
            console.log(ref)
          })
      },
      getParamsFromUrl() {
        let params = {} // 创建一个空对象存储参数
        let urlOr = window.location.href
        let url = urlOr.replace(/%20/g, '+')
        // 获取URL中的查询部分（即参数部分）
        var queryString = url.split('?')[1]

        if (queryString) {
          // 解析参数字符串为键值对
          var keyValuePairs = queryString.split('&')

          // 遍历键值对，将其存储在params对象中
          keyValuePairs.forEach(function (keyValuePair) {
            var pair = keyValuePair.split('=')
            var key = pair[0] // 键
            var value = pair[1] // 值
            // 解码键和值
            key = decodeURIComponent(key)
            value = decodeURIComponent(value.replace(/%20/g, '+')) // 将+替换为空格
            params[key] = value // 将键值对存储在params对象中
          })
        }

        return params
      },
      getParam(parm) {
        // 获取当前URL
        let currentUrl = window.location.href
        console.log(currentUrl)
        localStorage.setItem('usernssame', currentUrl)
        let getqyinfo = currentUrl.split('?')[1]
        // 解析URL，提取参数
        let urlParams = new URLSearchParams('?' + getqyinfo)
        // 获取名为"phone"的参数的值
        let phoneParam = urlParams.get(parm) || undefined
        return phoneParam
      },
      inFun() {
        const timmer = setInterval(() => {
          if (this.$store.state.account.user?.useruid) {
            addLoginTime(this.$store.state.account.user.useruid)
            clearInterval(timmer)
          }
        }, 1000)
      },
      outFn() {
        addLoginOutTime(this.$store.state.account.user.useruid)
      },
      locationFun() {
        // let host = window.location.host
        getIp().then((data) => {
          if (data == '124.167.237.136' || data == '124.167.237.137' || data == '124.167.249.34') {
            this.$store.commit('account/setfreeLogin', false)
          } else {
            this.$store.commit('account/setfreeLogin', true)
          }
        })
        // .catch((err) => {
        //   this.freeLogin = true
        // })
      },

      // 有问题

      // 刷新页面
      reload() {
        this.isShow = false
        this.$nextTick(() => {
          this.isShow = true
        })
      },
      checkThrirdToken() {
        let tokenResult = this.parseThirdToken()
        if (!tokenResult) {
          return false
        }
        this.$store.dispatch('account/checkThirdToken', tokenResult).then(() => {})

        return true
      },

      parseThirdToken() {
        let params = new URLSearchParams(location.search.substring(1))
        if (!params) {
          return false
        }

        let token = params.get('ry_token')
        if (token) {
          return { thirdName: 'rongyi', thirdToken: token } //['rongyi', token];
        }

        token = params.get('xh_token')
        if (token) {
          return { thirdName: 'xinhua', thirdToken: token } //['xinhua', token];
        }

        return false
      },
      reportPageTime() {
        const startTime = localStorage.getItem('__page_open_time__')
        const endTime = localStorage.getItem('__page_close_time__')
        if (startTime && endTime) {
          const duration = parseInt(endTime) - parseInt(startTime)
          const { aplus_queue } = window
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['user_online', 'CLK', { [this.$store.state.account.user.useruid]: duration }]
          })
        }

        localStorage.setItem('__page_open_time__', moment().format('X'))
        window.onbeforeunload = function (event) {
          localStorage.setItem('__page_close_time__', moment().format('X'))
        }
      },

      setLanguage(lang) {
        this.$i18n.locale = lang
        switch (lang) {
          case 'CN':
            this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
            break
          case 'HK':
            this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
            break
          case 'US':
          default:
            this.locale = require('ant-design-vue/es/locale-provider/en_US').default
            break
        }
      },
      setHtmlTitle() {
        const route = this.$route
        const key = route.path === '/' ? 'home.name' : getI18nKey(route.matched[route.matched.length - 1].path)
        // document.title = process.env.VUE_APP_NAME + ' | ' + this.$t(key)
        document.title = process.env.VUE_APP_NAME
      },
      popContainer() {
        return document.getElementById('popContainer')
      },
      isTimeOut() {
        var currentTime = new Date().getTime()
        // 判断上次最后一次点击的时间和这次点击的时间间隔
        if (currentTime - this.lastTime > this.timeOut) {
          const token = localStorage.getItem('access-token')
          if (!token) {
            return
          }
          message.warning('会话已超时，系统将自动退出登录')
          logout(this)
        } else {
          // 如果在期限内点击，则把这次点击的时间覆盖掉之前存的最后一次点击的时间
          this.lastTime = new Date().getTime()
        }
      },
      GetUrlParam(name) {
        var url = window.location.href //正式
        if (url.lastIndexOf('?') + 1) {
          // var url = "http://hongqi.wengegroup.com:9080/usm/static/index.html?encryptTime=B4241FFE1AC9990974D2C4A0EFFF823D&date=1625013047";//测试
          let params = url.substr(url.lastIndexOf('?') + 1).split('&')
          // for (let i = 0; i < params.length; i++) {
          //   let param = params[i]
          //   let key = param.split('=')[0]
          //   let value = param.split('=')[1]
          //   if (key === name) {
          //     return value
          //   }
          // }
          // return ''
          return params
        } else {
          return ''
        }
      },
      setLoginSuccess(accessToken, refreshToken, userInfo, partyOrgId, brandName) {
        setLocalLogined(this, accessToken, refreshToken, partyOrgId, brandName)
        setLocalUser(this, userInfo)
      }
    }
  }
</script>

<style lang="less">
  .ant-popover-inner-content {
    padding: 12px 16px !important;
  }

  .ant-popover-buttons .ant-btn-sm {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-popover-buttons .ant-btn-primary {
    color: #fff !important;
  }

  .medias img,
  .medias video,
  .medias iframe {
    width: 100%;
    height: 100%;
  }

  h3,
  p {
    word-wrap: break-word;
    word-break: break-all;
  }
</style>
