import { request, METHOD } from '@/utils/request'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const { POST: post, GET: get } = METHOD

// 获取文章的详细信息
async function getArticleDetail(docuid) {
  return request(`${BASE_URL}/getArticleDetail`, post, {
    docuid: docuid
  })
}

// 获取指定文章相关的主题词
async function getArticleKeywords(docuid) {
  return request(`${BASE_URL}/getArticleKeywords`, post, {
    docuid: docuid
  })
}

// 获得文章锚点原话列表
async function getArticleSpeechList(docuid) {
  return request(`${BASE_URL}/getArticleSpeechList`, post, {
    docuid: docuid
  })
}

// 获得相关推荐文章列表
async function getRecommendArticles(docuid) {
  return request(`${BASE_URL}/getRecommendArticles`, post, {
    docuid: docuid
  })
}

export default {
  getArticleDetail,
  getArticleKeywords,
  getArticleSpeechList,
  getRecommendArticles
}
