import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'

export function changeTime(t) {
  try {
    const theLength = _.size(_.toString(t))
    if (!t || theLength === 0) return ''
    let newTime = ''
    if (theLength === 13) {
      newTime = dayjs(t).format('YYYY-MM-DD')
    } else if (theLength === 10) {
      const m = _.multiply(t, 1000)
      newTime = dayjs(m).format('YYYY-MM-DD')
    }
    return newTime
  } catch (error) {}
}

export function isExpired(time) {
  const now = moment()
  const expireTime = moment(time * 1000)
  const day = expireTime.diff(now, 'days')
  if (day < 0) {
    // 过期
    return true
  } else {
    return false
  }
}
