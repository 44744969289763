<template>
  <div>
    <div class="mphone-container" @click="openMicrophone">
      <span class="earphone-icon"></span>
    </div>
    <a-modal
      ref="soundModal"
      :visible="visible"
      @cancel="handleCancel"
      :keyboard="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :width="'800px'"
      height="400px"
    >
      <template slot="title">
        <div class="record-title" v-if="showStartContainer">
          <span>
            <i class="microphone-btn-icon" @click="startRecord"></i>
            <label>点击开启语音</label>
          </span>
          <p>提示：请允许浏览器获取麦克风权限</p>
        </div>
        <div v-else class="record-title recording">
          <span>
            <div class="circle">
              <div class="big-circle"></div>
              <div class="small-circle" @click="pauseRecord">
                <i class="microphone-btn-icon"></i>
              </div>
            </div>
            <label>暂停或完成输入</label>
          </span>
          <p>提示：支持修改识别后的内容</p>
        </div>
      </template>
      <template slot="footer">
        <a-button type="primary" v-if="sceneType == 'home'" :disabled="disabled" icon="search" @click="finishRecog" size="large"> 问一下 </a-button>
        <a-button type="primary" v-if="sceneType == 'search'" :disabled="disabled" @click="finishRecog" size="large">
          <img src="@/assets/common/search-send-icon.svg" style="margin-right: 10px" alt="" /> 发送
        </a-button>
        <a-button type="primary" v-if="sceneType == 'graphic'" :disabled="disabled" @click="finishRecog" size="large">
          <img src="@/assets/common/book.svg" style="margin-right: 10px" alt="" /> 开始检索
        </a-button>
      </template>
      <div class="text-wrapper">
        <textarea class="text-area" :readOnly="readOnly" v-model="resultText"></textarea>
      </div>
    </a-modal>
  </div>
</template>
<script>
  import { SoundRecorder } from './recorder'
  import { getAiuiConfig } from '@/services/search'
  export default {
    components: {},
    props: {
      sceneType: {
        type: String,
        default: () => {
          return 'home'
        }
      }
    },
    data() {
      return {
        visible: false,
        resultText: '',
        showStartContainer: true,
        soundRecorder: null,
        readOnly: false,
        prevText: '',
        disabled: false,
        aiuiConfig: null
      }
    },
    methods: {
      openMicrophone() {
        if (!this.aiuiConfig) {
          getAiuiConfig().then((data) => {
            this.aiuiConfig = data.config
            this.openRecorder(this.aiuiConfig)
          })
        } else {
          this.openRecorder(this.aiuiConfig)
        }
      },
      openRecorder(aiuiConfig) {
        let config = aiuiConfig
        config.message = this.$message
        config.onTextChange = this.onTextChange
        config.onSocketClosed = this.onSocketClosed

        this.soundRecorder = new SoundRecorder(config)
        this.soundRecorder.init(() => {
          if (!this.soundRecorder.audioContext) {
            return
          }
          this.openModal()
        })
      },
      openModal() {
        this.visible = true
      },
      startRecord() {
        this.prevText = this.resultText
        this.readOnly = true
        this.disabled = true
        this.showStartContainer = false
        this.soundRecorder.start()
      },
      onSocketClosed() {
        this.pauseRecord()
      },
      pauseRecord() {
        this.soundRecorder.stop()
        this.showStartContainer = true
        this.disabled = false
        this.readOnly = false
      },
      handleCancel() {
        this.pauseRecord()
        this.resultText = ''
        this.soundRecorder.destroy()

        this.visible = false
      },
      onTextChange(text) {
        this.resultText = this.prevText + text
      },
      finishRecog() {
        if (!_.trim(this.resultText)) {
          return
        }
        this.$emit('finishRecog', _.trim(this.resultText))
        setTimeout(() => {
          this.handleCancel()
        }, 500)
      }
    },
    computed: {},
    computed: {},
    watch: {}
  }
</script>
<style scoped lang="less">
  /deep/.ant-modal-content {
    padding: 40px;
  }
  /deep/.ant-modal-header {
    padding-top: 0px;
    border: none;
  }
  .circle {
    justify-content: center;
    position: relative;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
  }
  .big-circle {
    border-radius: 50%;
    background: rgba(222, 55, 55, 0.3);
    width: 100%;
    height: 100%;
    animation: twinkling 1s infinite ease-in-out;
    animation-fill-mode: both;
    position: absolute;
  }
  .small-circle {
    position: absolute;
  }
  @keyframes twinkling {
    0% {
      opacity: 0.2;
      transform: scale(1);
    }
    50% {
      opacity: 0.5;
      transform: scale(1.12);
    }
    100% {
      opacity: 0.2;
      transform: scale(1);
    }
  }
  .text-area {
    width: 100%;
    height: 200px;
    resize: none;
    border: 1px solid rgba(238, 238, 238, 1);
    border-radius: 6px;
    outline: none;
    padding: 15px;
    font-size: 14px;
    color: #000000;
  }
  .record-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
  }
  .record-title i {
    cursor: pointer;
  }
  .record-title i:hover {
    opacity: 0.8;
  }
  .record-title span {
    display: flex;
    align-items: center;
  }
  .record-title span label {
    color: #de3737;
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
  }
  .recording span label {
  }
  .record-title p {
    font-size: 14px;
    color: #999999;
    font-weight: 400;
  }
  /deep/.ant-modal-body {
    padding: 0px 24px;
  }
  /deep/.ant-modal-footer {
    border-top: none;
    text-align: center;
    padding: 20px 16px 0px;
  }
  /deep/.ant-modal-footer .ant-btn-primary[disabled] {
    background: #de3737;
    border: none;
    opacity: 0.6;
  }
  /deep/.ant-modal-footer .ant-btn {
    width: 140px;
    height: 44px;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }
  /deep/.ant-modal-footer img {
    width: 18px;
    height: 18px;
  }
  /deep/.ant-modal-footer .ant-btn .anticon-search {
    margin-top: 5px;
    font-size: 20px;
  }
  .microphone-btn-icon {
    background: url('~@/assets/common/microphone-btn-icon.svg') no-repeat;
    width: 60px;
    height: 60px;
    display: inline-block;
    background-size: contain;
  }
  .earphone-icon {
    background: url('~@/assets/common/earphone-default-icon.svg') no-repeat;
    width: 28px;
    height: 40px;
    display: inline-block;
    background-size: contain;
    margin-right: 20px;
  }
  .earphone-icon:hover {
    background: url('~@/assets/common/earphone-hover-icon.svg') no-repeat;
  }
</style>
